export const Contact = () => {
  return (
    <>
    <br />
    <p className="center">Feel free to contact me via email at <a href="mailto:sunguac@gmail.com">sunguac@gmail.com</a></p>
    <br />
    </>
  )
}

export default Contact