export const Blog = () => {
  return (
    <>
    <br />
    <p className="center">Under construction...</p>
    <br />
    </>
  );
};

export default Blog;
